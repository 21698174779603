import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { Stack } from '@grnhse/seedling/lib/birch/layouts/stack';
import { CompactRow } from '@grnhse/seedling/lib/birch/layouts/compact_row';
import Popper from '@mui/material/Popper';
import { Editor as tinymce } from 'tinymce';
import { FullPalette } from '@grnhse/seedling/lib/birch/colors';
import styled from 'styled-components';
import { Grid } from '@grnhse/seedling/lib/birch/layouts/grid';
import { Body, ExternalLink } from '@grnhse/seedling/lib/birch/typography';
import IconButton from '@grnhse/seedling/lib/birch/components/buttons/icon_buttons';
import DeleteIcon from '@grnhse/seedling/lib/birch/icons/delete';
import { makeAiRequest } from '../../lib/api';
import { Payload } from '../../lib/api';
import { withLocalePrefix } from '../../../shared/utils/translation';
import SkeletonLoader from '@grnhse/seedling/lib/birch/components/skeleton_loader';
import {
  ACTION_TYPE,
  TONE_TYPE,
  ACTIONS_OPTIONS,
  AI_PRODUCT,
} from '../../lib/consts';
import { ExpandableButton } from '@grnhse/seedling/lib/birch/components/buttons';
import RefreshIcon from '@grnhse/seedling/lib/birch/icons/refresh';
import ArrowLeftIcon from '@grnhse/seedling/lib/birch/icons/arrow_left';
import ArrowRightIcon from '@grnhse/seedling/lib/birch/icons/arrow_right';
import { useResizeObserver } from 'shared/utils/useResizeObserver';
import {
  ExpandableMenu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@grnhse/seedling/lib/birch/components/expandable_menu';
import { IconTooltip } from '@grnhse/seedling/lib/birch/components/tooltips';
import { NeutralTag } from '@grnhse/seedling/lib/birch/components/tags';
import ActionMenu from './action_menu';
import DOMPurify from 'dompurify';
import { Option } from '../../hooks/useAiAssistant';

import type { Placement, ModifierArguments } from '@popperjs/core';

const t = withLocalePrefix('ai_tools.popper');

type Props = {
  options: { menuOptions: Option[]; toneOptions: Option[] };
  action: ACTION_TYPE;
  selectedText: string;
  selectedTone: TONE_TYPE | '';
  editor: tinymce;
  anchorEl: HTMLElement;
  selectedNode: HTMLElement;
  open: boolean;
  close: () => void;
  product?: AI_PRODUCT;
};

type History = {
  originalText: string;
  action: ACTION_TYPE;
  tone: TONE_TYPE | '';
  updatedText: string;
  retry: boolean;
};

type EnhanceProps = {
  retry?: boolean;
  enhanceMethod?: string;
  tone?: string;
  textToEnhance?: string;
};

const Content = styled(Grid)`
  max-height: 300px;
  padding: 8px 16px;
  overflow: auto;

  @media (max-height: 950px) {
    max-height: 225px;
  }

  @media (max-height: 850px) {
    max-height: 175px;
  }

  @media (max-height: 750px) {
    max-height: 125px;
  }

  p > p:not(:last-child) {
    margin-bottom: 12px;
  }

  ul {
    list-style-type: disc;
    margin-left: 40px;
    margin-bottom: 12px;
  }

  ol {
    list-style: decimal;
    margin-left: 40px;
    margin-bottom: 12px;
  }
`;

const ErrorBody = styled(Body)`
  color: ${FullPalette.POPPY_600};
`;

const TextBody = styled(Body)`
  padding: 8px 0;
`;

const MenuBar = styled(Grid)`
  background-color: ${FullPalette.GREY_E25};
  border: 1px solid ${FullPalette.GREY_E500};
  display: flex;
  padding: 8px 16px;
`;

const Footer = styled(Grid)`
  background-color: ${FullPalette.GREY_E25};
  border: 1px solid ${FullPalette.GREY_E500};
  display: flex;
  padding: 8px 16px;
  border-radius: 0 0 5px 5px;
`;

const AiAssistantPopper = ({
  options,
  action,
  selectedText,
  selectedTone,
  editor,
  anchorEl,
  selectedNode,
  open,
  close,
  product,
}: Props) => {
  const anchorElRef = useRef(anchorEl);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [history, setHistory] = useState<History[]>([]);
  const [cursor, setCursor] = useState(0);
  const [achorElWidth, setAnchorElWidth] = useState(
    anchorElRef.current?.offsetWidth || 400
  );
  const [popperPlacement, setPopperPlacement] = useState<Placement>('bottom');

  const selectedHistory = history[cursor];
  const displayText = selectedHistory?.updatedText || selectedText;
  const displayTone = selectedHistory?.tone || selectedTone;
  const displayAction = selectedHistory?.action || action;
  const retried = selectedHistory?.retry || false;

  useResizeObserver((offset: { height: number; width: number }) => {
    setAnchorElWidth(offset.width);
  }, anchorElRef);

  const popperBehavior = [
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['top'],
      },
    },
    {
      name: 'onUpdate',
      fn: (args: ModifierArguments<{}>) => {
        setPopperPlacement((prev) => {
          if (args.state.placement !== prev) {
            return args.state.placement;
          }
          return prev;
        });
      },
    },
  ];

  const boxStyle = {
    border: `1px solid ${FullPalette.GREY_600}`,
    borderRadius: '5px',
    boxShadow: `0px 3px 11px 0px ${FullPalette.EVERGREEN_E200}`,
    bgcolor: 'background.paper',
    width: `${achorElWidth - 32}px`,
  };

  let topPosition: string | undefined;

  if (open) {
    selectedNode.scrollIntoView({ block: 'nearest' });
    editor.getBody().style.overflow = 'hidden';

    const rect = selectedNode.getBoundingClientRect();
    const editorHeight = editor.iframeElement?.offsetHeight || 0;

    const hasTextContent = (selectedNode?.textContent?.trim()?.length ?? 0) > 0;
    if (rect.height > editorHeight || !hasTextContent) {
      topPosition = undefined;
    } else {
      topPosition = `${rect.bottom - editorHeight}`;
    }
  }

  const popperStyle = {
    zIndex: 1000,
    top:
      popperPlacement === 'top' || !topPosition
        ? undefined
        : `${topPosition}px !important`,
  };

  const enhanceText = async ({
    retry = false,
    tone = displayTone,
    enhanceMethod = displayAction,
    textToEnhance = displayText,
  }: EnhanceProps = {}) => {
    const trimmedText = textToEnhance
      .replace(/&nbsp;\s*/g, '')
      .replace(/<p><\/p>/g, '')
      .trim();

    if (trimmedText === '') {
      setError(t('no_selection_error'));
      return;
    }

    setError(null);
    setIsLoading(true);

    const payload: Payload = {
      text: textToEnhance,
      product: product,
    };
    if (tone) {
      payload.tone_descriptor = tone as TONE_TYPE;
    }

    try {
      const response = await makeAiRequest(enhanceMethod, payload);

      const trimmedResponse = response
        .replace(/&nbsp;\s*/g, '')
        .replace(/<\/?p>/g, '')
        .trim();
      const accessDeniedRegex = /access\s+denied[.\s]*$/i;
      if (accessDeniedRegex.test(trimmedResponse)) {
        throw 'Possible prompt engineering detected';
      }

      // Sanitizes the text and reformats the response so it matches our existing formatting
      const updatedText = DOMPurify.sanitize(response)
        .replace(/<p>&nbsp;<\/p>/g, '')
        .replace(/\n/g, '')
        .replace(/<\/p><p>/g, '</p><p></p><p>');

      const newHistory = {
        originalText: textToEnhance,
        action: enhanceMethod,
        tone,
        updatedText,
        retry,
      };

      setHistory([...history, newHistory]);
    } catch (e) {
      setError(t('error'));
    } finally {
      setIsLoading(false);
    }
  };

  const retry = () => {
    return enhanceText({
      retry: true,
      enhanceMethod: displayAction,
      tone: displayTone,
      textToEnhance: selectedHistory.originalText,
    });
  };

  const iterate = ({ enhanceMethod, tone }: EnhanceProps = {}) => {
    return enhanceText({
      enhanceMethod,
      tone,
      textToEnhance: displayText,
    });
  };

  useEffect(() => {
    if (open) {
      enhanceText({
        retry: false,
        enhanceMethod: displayAction,
        tone: displayTone,
      });
    }
  }, [open]);

  useEffect(() => {
    setCursor(history.length - 1);
  }, [history]);

  const onClose = () => {
    editor.getBody().style.overflow = '';
    setHistory([]);
    setError(null);
    close();
  };

  const moveToCursor = (newCursor: number) => {
    if (newCursor < 0 || newCursor >= history.length) {
      return;
    }

    const historyEntry = history[newCursor];
    if (!historyEntry) {
      return;
    }

    setCursor(newCursor);
  };

  const replaceText = () => {
    const fullBodyText = editor.getContent();

    // We want to replace part/all of the text, but our display text may be wrapped in HTML tags.
    // If the selected text is not wrapped in HTML tags, then we need to strip these tags out
    const wrappedHtmlTagsRegex = /^<[^>]+>|<\/[^>]+>$/g;
    const selectionWrappedInHtmlTags = wrappedHtmlTagsRegex.test(selectedText);
    const replacementText = selectionWrappedInHtmlTags
      ? displayText
      : displayText.replace(wrappedHtmlTagsRegex, '');

    const newContent = fullBodyText.replace(selectedText, replacementText);
    editor.setContent(newContent);

    onClose();
  };

  const appendText = () => {
    const fullBodyText = editor.getContent();

    // Wrap the displayed text so it displays correctly
    const textToAppend = `<p></p>${displayText}<p></p>`;
    const newContent = fullBodyText.replace(
      selectedText,
      selectedText + textToAppend
    );

    editor.setContent(newContent);

    onClose();
  };

  const toneDescription = () => {
    if (retried) {
      return t('retried_tone', { tone: displayTone });
    }

    return t('changed_tone', { tone: displayTone });
  };

  const actionDescription = () => {
    const changedActions: { [key: string]: string } = {
      [ACTIONS_OPTIONS.summarize_text]: t('made_shorter'),
      [ACTIONS_OPTIONS.expand_text]: t('made_longer'),
      [ACTIONS_OPTIONS.grammar_check]: t('checked_grammar'),
      [ACTIONS_OPTIONS.revise_text]: t('improved'),
    };

    const retriedActions: { [key: string]: string } = {
      [ACTIONS_OPTIONS.summarize_text]: t('retried_shorter'),
      [ACTIONS_OPTIONS.expand_text]: t('retried_longer'),
      [ACTIONS_OPTIONS.grammar_check]: t('retried_grammar'),
      [ACTIONS_OPTIONS.revise_text]: t('retried_improve'),
    };

    if (retried) {
      return retriedActions[displayAction] || '';
    }

    return changedActions[displayAction] || '';
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorElRef.current}
      modifiers={popperBehavior}
      sx={popperStyle}
    >
      <Box sx={boxStyle}>
        <Stack spacing={0}>
          <Content item>
            {isLoading ? (
              <SkeletonLoader
                columnsPerRow={1}
                rows={2}
                cellHeight={'40px'}
                spaceBetweenRows={'8px'}
              />
            ) : (
              <>
                {error ? (
                  <ErrorBody>{error}</ErrorBody>
                ) : (
                  <TextBody
                    data-provides="display-text"
                    dangerouslySetInnerHTML={{ __html: displayText }}
                  />
                )}
              </>
            )}
          </Content>
          <MenuBar item>
            {isLoading ? (
              <CompactRow justifyContent={'flex-start'}>
                <Body metadata>{t('writing')}</Body>
              </CompactRow>
            ) : (
              <CompactRow justifyContent="space-between">
                <CompactRow>
                  <IconButton
                    icon={ArrowLeftIcon}
                    data-provides={'ai-popper-left-arrow'}
                    label={'rewind'}
                    size="sm"
                    onClick={() => moveToCursor(cursor - 1)}
                    disabled={cursor < 1}
                  />
                  <Body metadata>
                    {t('cursor_of_total', {
                      cursor: cursor + 1,
                      total: history.length,
                    })}
                  </Body>
                  <IconButton
                    icon={ArrowRightIcon}
                    data-provides={'ai-popper-right-arrow'}
                    label={'forward'}
                    size="sm"
                    onClick={() => moveToCursor(cursor + 1)}
                    disabled={cursor >= history.length - 1}
                  />
                  {displayAction === ACTIONS_OPTIONS.change_tone &&
                    displayTone && (
                      <Body data-provides="tone-description">
                        {toneDescription()}
                      </Body>
                    )}
                  {displayAction !== ACTIONS_OPTIONS.change_tone && (
                    <Body data-provides="action-description" metadata>
                      {actionDescription()}
                    </Body>
                  )}
                  <IconTooltip
                    label={t('retry')}
                    ariaLabel={t('retry')}
                    data-provides={'ai-popper-refresh'}
                    icon={() => (
                      <IconButton
                        icon={RefreshIcon}
                        label={t('retry')}
                        onClick={() => retry()}
                      />
                    )}
                  />
                  <ActionMenu onMenuPick={iterate} options={options} />
                </CompactRow>
                <CompactRow justifyContent="flex-end">
                  <ExpandableMenu>
                    <ExpandableButton as={MenuButton}>
                      {t('save')}
                    </ExpandableButton>
                    <MenuList>
                      <MenuItem
                        data-provides={'ai-popper-replace'}
                        onSelect={replaceText}
                      >
                        {t('replace_selection')}
                      </MenuItem>
                      <MenuItem
                        data-provides={'ai-popper-insert-below'}
                        onSelect={appendText}
                      >
                        {t('insert')}
                      </MenuItem>
                    </MenuList>
                  </ExpandableMenu>
                  <IconButton
                    icon={DeleteIcon}
                    label={'close'}
                    onClick={onClose}
                    data-provides={'ai-popper-delete'}
                  />
                </CompactRow>
              </CompactRow>
            )}
          </MenuBar>
        </Stack>
      </Box>
    </Popper>
  );
};

export default AiAssistantPopper;
